import React from 'react';
import { graphql } from 'react-apollo';
import { compose, withProps } from 'utils/compose';

import { withUpdateAuth, showWhenAuthorized, withAuth } from 'app/Auth';
import Button from 'components/Button';

import Logout from './logout.graphql';

const logoutHandler = (setSession, logout) => async (ev) => {
  ev.preventDefault();
  try {
    await logout();
  } catch (e) {
    // console.log(e.message);
  }

  localStorage.removeItem('sessionToken');
  setSession(false);
};

const LogoutButton = ({ logout, setSession, auth, ...props }) => (
  <div>
    <span>{auth.user.email}</span>
    {' '}
    <Button {...props} type="button" onClick={logout}>
      Logout
    </Button>
  </div>
);

export default compose(
  showWhenAuthorized,
  withAuth,
  withUpdateAuth,
  graphql(Logout, {
    props: ({ mutate }) => ({ logout: mutate }),
  }),
  withProps(({ setSession, logout }) => ({ logout: logoutHandler(setSession, logout) }))
)(LogoutButton);
