import ApolloClient from 'apollo-boost';
import { Mutation, defaults } from 'modules';
import { useConfig, getCustomConfig } from 'utils/misc';

export const appId = process.env.PARSE_APP_ID;
export const appKey = process.env.PARSE_JAVASCRIPT_KEY;
export const graphqlUri = process.env.PARSE_SERVER_GRAPHQL_URL;

const config = useConfig({
  appId,
  appKey,
  graphqlUri,
}, getCustomConfig());

const client = new ApolloClient({
  uri: config.graphqlUri,
  clientState: {
    defaults: {
      ...defaults,
    },
    resolvers: {
      Mutation: {
        ...Mutation,
      },
    },
  },
  request: async (operation) => {
    const sessionToken = localStorage.getItem('sessionToken');
    const headers = {
      'x-parse-application-id': config.appId,
      'x-parse-javascript-key': config.appKey,
    };
    if (sessionToken) {
      headers['x-parse-session-token'] = sessionToken;
    }
    operation.setContext({
      headers,
    });
  },
});

export default client;
