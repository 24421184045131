exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".formHolder__2Vv{display:flex;flex-direction:column;justify-content:center;align-items:center;width:100%;margin-left:auto;margin-right:auto;padding:20px 0;border-radius:4px;border:1px solid #dedede;background-color:#fff}.formHolder__2Vv>form{width:calc(100% - 40px)}.formHolder__2Vv a{padding-bottom:2px;color:#0af;font-size:14px;font-weight:300;border-bottom:1px solid rgba(0,170,255,0.4)}.formHolder__2Vv a:hover{color:#0099e6}\n", "", {"version":3,"sources":["/home/myovchev/development/nodejs/parse-graphql/src/components/FormHolder/src/components/FormHolder/index.scss"],"names":[],"mappings":"AAAA,iBACE,aACA,sBACA,uBACA,mBACA,WACA,iBACA,kBACA,eACA,kBACA,yBACA,qBAAsB,CAXxB,sBAcI,uBAAwB,CAd5B,mBAkBI,mBACA,WACA,eACA,gBACA,2CAAkC,CAtBtC,yBAyBM,aAAuB,CACxB","file":"index.scss","sourcesContent":[".formHolder {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  margin-left: auto;\n  margin-right: auto;\n  padding: 20px 0;\n  border-radius: 4px;\n  border: 1px solid #dedede;\n  background-color: #fff;\n\n  > form {\n    width: calc(100% - 40px);\n  }\n\n  a {\n    padding-bottom: 2px;\n    color: #0af;\n    font-size: 14px;\n    font-weight: 300;\n    border-bottom: 1px solid rgba(#0af, .4);\n\n    &:hover {\n      color: darken(#0af, 5%);\n    }\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"formHolder": "formHolder__2Vv"
};