import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Form, { FormHandler, getFieldErrors } from 'components/Form';
import Input from 'components/Input';
import Notify from 'components/Notify';
import SegmentSelect from 'components/SegmentSelect';

class TodoForm extends Component {
  static segmentItems = ['All', 'Active', 'Completed'];

  state = {
    fields: {
      label: '',
    },
    loading: false,
    errors: {},
  }

  inputRef = React.createRef()

  static propTypes = {
    addTodo: PropTypes.func.isRequired,
  }

  handler = new FormHandler(this, {
    submit: () => {
      const { addTodo } = this.props;
      const { fields } = this.state;

      return addTodo(fields.label);
    },
    onSuccess: () => {
      this.setState({
        fields: { label: '' },
      }, this.focus);
    },
  });

  focus = () => {
    this.inputRef.current.customFocus();
  }

  handleSubmit = async (ev) => {
    ev.preventDefault();
    ev.persist();

    const { fields } = this.state;
    if (fields.label.trim() === '') return;

    this.handler.handleSubmit({
      values: fields,
    }, ev);
  }

  handleSegmentClick = (activeSegment) => {
    const { history } = this.props;
    switch (activeSegment) {
      case 1:
        history.push('/active');
        break;
      case 2:
        history.push('/completed');
        break;

      default:
        history.push('/');
        break;
    }
    // this.setState({
    //   activeSegment,
    // });
  };

  render() {
    const { loading, errors: errObject, fields } = this.state;
    const { location } = this.props;
    const { errors } = getFieldErrors(fields, errObject);
    const segmentValues = ['/', '/active', '/completed'];

    const activeSegment = segmentValues.indexOf(location.pathname.toLowerCase());

    return (
      <Form onSubmit={this.handleSubmit} method="post" data-loading-text="Log In">
        <h2>Add Todo</h2>

        <Notify error message={errors.__default__} />

        <Input
          ref={this.inputRef}
          autoFocus
          name="label"
          placeholder="type todo and press Enter"
          type="text"
          autoComplete="todo"
          value={fields.label}
          disabled={loading}
          onChange={this.handler.onChange}
        />

        <SegmentSelect
          labels={TodoForm.segmentItems}
          values={segmentValues}
          active={activeSegment}
          onChange={this.handleSegmentClick}
        />
      </Form>
    );
  }
}


export default TodoForm;
