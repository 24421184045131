import React from 'react';

import ConfigView from './View';
import Form from './Form';

export default () => (
  <ConfigView>
    <Form />
  </ConfigView>
);
