import React from 'react';
import { Route } from 'react-router-dom';

import Config from './Config';

export default {
  routes: [
    <Route key="config" exact path="/config" component={Config} />,
  ],
};
