exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".screenLoading__3sS{display:flex;align-items:center;justify-content:center;position:absolute;top:0;left:0;z-index:20;width:100vw;height:100vh;transition:all .4s;opacity:0;animation:fadeIn__2oQ .4s forwards}.screenLoading__3sS.hide__20U{animation:fadeOut__3ge .4s forwards}@keyframes fadeIn__2oQ{0%{opacity:0}100%{opacity:1}}@keyframes fadeOut__3ge{0%{opacity:1}100%{opacity:0}}\n", "", {"version":3,"sources":["/home/myovchev/development/nodejs/parse-graphql/src/components/ScreenLoading/src/components/ScreenLoading/index.scss"],"names":[],"mappings":"AAEA,oBACE,aACA,mBACA,uBACA,kBACA,MACA,OACA,WACA,YACA,aACA,mBACA,UACA,kCAA8B,CAZhC,8BAeI,mCAA+B,CAChC,uBAID,GACE,SAAU,CAEZ,KACE,SAAU,CAAA,CAId,wBACE,GACE,SAAU,CAEZ,KACE,SAAU,CAAA,CAAA","file":"index.scss","sourcesContent":["@import '~assets/css/_globals.scss';\n\n.screenLoading {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 20;\n  width: 100vw;\n  height: 100vh;\n  transition: all .4s;\n  opacity: 0;\n  animation: fadeIn .4s forwards;\n\n  &.hide {\n    animation: fadeOut .4s forwards;\n  }\n}\n\n@keyframes fadeIn {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n\n@keyframes fadeOut {\n  0% {\n    opacity: 1;\n  }\n  100% {\n    opacity: 0;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"screenLoading": "screenLoading__3sS",
	"fadeIn": "fadeIn__2oQ",
	"hide": "hide__20U",
	"fadeOut": "fadeOut__3ge"
};