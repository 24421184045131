import { graphql } from 'react-apollo';

import { queryErrorsToArray, connectionToData } from 'utils/misc';
import {
  GetTodos,
  AddTodo,
  ToggleTodo,
} from './todo.graphql';

// Poll new results every 30 seconds
const POLL_INTERVAL = 1000 * 30;
const QUERY_OPTIONS = {
  pollInterval: POLL_INTERVAL,
};

const refetchQueries = [{
  query: GetTodos,
  variables: { where: null },
}, {
  query: GetTodos,
  variables: { where: { completed: false } },
}, {
  query: GetTodos,
  variables: { where: { completed: true } },
}];

const enhancedProps = ({ data: { findTodo, error, loading } }) => (
  {
    errors: queryErrorsToArray(error),
    loading,
    todos: connectionToData(findTodo).entities,
  }
);

export const withAllTodos = graphql(GetTodos, {
  props: enhancedProps,
  options: {
    ...QUERY_OPTIONS,
    variables: {
      where: null,
    },
  },
});

export const withActiveTodos = graphql(GetTodos, {
  props: enhancedProps,
  options: {
    ...QUERY_OPTIONS,
    variables: {
      where: { completed: false },
    },
  },
});

export const withCompletedTodos = graphql(GetTodos, {
  props: enhancedProps,
  options: {
    ...QUERY_OPTIONS,
    variables: {
      where: { completed: true },
    },
  },
});

export const withAddTodo = graphql(AddTodo, {
  props: ({ mutate }) => ({
    addTodo: label => mutate({
      variables: { todo: { label, completed: false } },
      refetchQueries,
      // Update query example. It should be applied on
      // update: (cache, { data: { addTodo } }) => {
      //   const variables = { where: null };
      //   const { findTodo } = cache.readQuery({ query: GetTodos, variables });
      //   const qry = {
      //     query: GetTodos,
      //     variables,
      //     data: {
      //       findTodo: {
      //         ...findTodo,
      //         edges: [
      //           {
      //             node: addTodo.object,
      //             __typename: 'TodoEdge',
      //           },
      //           ...findTodo.edges,
      //         ],
      //       },
      //     },
      //   };
      //   // Example Update cache of both getAll and getActive queries
      //   cache.writeQuery(qry);
      //   cache.writeQuery({ ...qry, variables: { completed: false } });
      //   // TOGGLE TODO mutation should do a more complex cache update
      //   // 1. Get new completed value; 2. based on it remove the node from
      //   // appropriate query cache. We just go lazy with refetchQueries ;)
      // },
    }),
  }),
});

export const withToggleTodo = graphql(ToggleTodo, {
  props: ({ mutate }) => (
    {
      createToggleTodo: ({ id, completed }) => () => (
        mutate({
          variables: { id, completed: !completed },
          refetchQueries,
        })
      ),
    }
  ),
});
