import React, { PureComponent } from 'react';
import styles from './index.scss';

import LoaderDots from '../LoaderDots';

export default class ScreenLoading extends PureComponent {
  state = {};

  componentDidUpdate(prevProps) {
    const {
      screenLoading: propsLoading,
      screenStopLoading,
    } = this.props;

    if (propsLoading && screenStopLoading && !prevProps.screenStopLoading) {
      setTimeout(() => {
        this.setState({ hide: true });
      }, 1000);
    }
  }

  onAnimationEnd = async () => {
    const { resetLoading } = this.props;
    const { hide } = this.state;
    if (hide) {
      await resetLoading();
      // console.log('end');
    }
  }

  render() {
    const { screenLoading, screenMounted } = this.props;
    const { hide } = this.state;

    if (!screenLoading || !screenMounted) return null;

    return (
      <div className={[styles.screenLoading, hide ? styles.hide : ''].join(' ')} onAnimationEnd={this.onAnimationEnd}>
        <LoaderDots />
      </div>
    );
  }
}
