import validateHelpers from 'utils/validations';

const PASS_MIN_LENGTH = 5;
const PASS_MAX_LENGTH = 20;

const messagePasswdReError = "Password confirmation doesn't match";

export default {
  email: [
    validateHelpers.required(),
    validateHelpers.email(),
  ],
  password: [
    validateHelpers.required(),
    validateHelpers.minmaxLength({
      min: PASS_MIN_LENGTH,
      max: PASS_MAX_LENGTH,
    }),
  ],
  passwordConfirm: [
    validateHelpers.required(),
    (value, ctx) => {
      if (ctx.values.password && value !== ctx.values.password) {
        return { message: messagePasswdReError };
      }
      return false;
    },
  ],
};
