import React from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { withRouterHistory } from 'utils/compose';
import Icon from 'components/Icon';

import styles from './index.scss';

const Header = ({ children, history }) => {
  const goBackHandler = (e) => {
    e.preventDefault();
    history.goBack();
  };

  return (
    <div className={styles.header}>
      <div className={styles.logos}>
        <Link to="/">
          <Icon name="parse-server-logo" width={40} height={40} fill="#169cee" />
          <Icon name="icon-heart" width={20} height={20} fill="#ed2655" />
          <Icon name="graphql-logo" width={40} height={40} fill="#e535ab" />
        </Link>
        <h1>
          ToDo Application with Parse Server & GraphQL
          <span>Powered by SashiDo.io</span>
        </h1>
      </div>
      <div className={styles.topBarItemsRight}>
        {children}

        <div className={styles.separator} />

        <Switch>
          <Route exact path="/config">
            <Link to="/" className={styles.configLink} onClick={goBackHandler}>
              <Icon name="icon-close" width={20} height={20} fill="#0af" />
            </Link>
          </Route>
          <Route path="/">
            <Link to="/config" className={styles.configLink}>
              <Icon name="icon-cog" width={20} height={20} fill="#0af" />
            </Link>
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default withRouterHistory(Header);
