import React, { Component } from 'react';

import generateCURL from 'utils/generateCURL';
import { getCustomConfig, setCustomConfig, resetCustomConfig } from 'utils/misc';

import FormHolder from 'components/FormHolder';
import Notify from 'components/Notify';
import Form, { FormHandler, getFieldErrors } from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';

import schema from './schema';
import validations from './validations';

class ConfigForm extends Component {
  state = {
    fields: {
      appId: '',
      appKey: '',
      graphqlUri: '',
    },
    message: '',
    refresh: 0,
    errors: {},
  }

  handler = new FormHandler(this, {
    submit: async () => {
      const { fields } = this.state;
      setCustomConfig(fields);
      return true;
    },
    validations,
    onSuccess: () => {
      this.setState(
        { message: 'Settings saved.', refresh: 5 },
        this.startRefresh
      );
    },
  });

  componentDidMount() {
    this.setState({
      fields: getCustomConfig(),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.refresh === 1) {
      clearInterval(this.refreshInterval);
      this.setState({ refresh: 0 });
      if (window) window.location.href = '/';
    }
  }

  startRefresh = () => {
    const { refresh } = this.state;
    if (refresh === 0) return;
    this.refreshInterval = setInterval(() => {
      this.setState(prevState => ({ refresh: prevState.refresh - 1 }));
    }, 1000);
  }

  handleSubmit = (ev) => {
    const { fields } = this.state;
    this.handler.handleSubmit({
      values: fields,
    }, ev);
  }

  resetConfig = () => {
    resetCustomConfig();
    this.setState({
      message: 'Conifg reset to defaults',
      fields: getCustomConfig(),
      refresh: 3,
    }, this.startRefresh);
  }

  onBlur = e => this.handler.onValidate(e, { values: this.state.fields }); // eslint-disable-line

  render() {
    const { message, errors: errObject, fields, refresh } = this.state;
    const { errors, hasError } = getFieldErrors(fields, errObject);

    return (
      <FormHolder>
        <Form onSubmit={this.handleSubmit}>
          <h2>Config</h2>
          <Notify success message={message} />
          {refresh > 0 && (
            <p>
              Refreshing page after
              {' '}
              {refresh}
              sec...
            </p>)
          }

          <Input
            autoFocus
            name="graphqlUri"
            placeholder="Parse Server GraphQL URL"
            type="text"
            autoComplete="off"
            icon={{ name: 'icon-cog', width: 16, height: 16, color: '#fff' }}
            value={fields.graphqlUri}
            error={errors.graphqlUri}
            onBlur={this.onBlur}
            onChange={this.handler.onChange}
          />

          <Input
            name="appId"
            placeholder="Parse Server App ID"
            type="text"
            autoComplete="off"
            icon={{ name: 'icon-cog', width: 16, height: 16, color: '#fff' }}
            value={fields.appId}
            error={errors.appId}
            onBlur={this.onBlur}
            onChange={this.handler.onChange}
          />

          <Input
            name="appKey"
            placeholder="Parse Server Javascript Key"
            type="text"
            autoComplete="off"
            icon={{ name: 'icon-cog', width: 16, height: 16, color: '#fff' }}
            value={fields.appKey}
            error={errors.appKey}
            onBlur={this.onBlur}
            onChange={this.handler.onChange}
          />

          <pre>
            {
              generateCURL(
                'Todo',
                fields.graphqlUri || undefined,
                fields.appId || undefined,
                'YOUR_MASTER_KEY',
                schema
              )
            }
          </pre>

          <Button
            type="button"
            disabled={hasError}
            onClick={this.handleSubmit}
          >
            Configure
          </Button>
          <Button
            type="button"
            onClick={this.resetConfig}
          >
            Reset to defaults
          </Button>
        </Form>
      </FormHolder>
    );
  }
}


export default ConfigForm;
