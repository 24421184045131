exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".app__19O{position:relative;width:100vw;height:100vh;padding-top:70px}.content__3Hk{position:relative;width:100vw;height:100%;transition:opacity, filter .4s}.content__3Hk.blur__XM-{opacity:.6;filter:blur(4px)}.configLink__1br{position:absolute;top:100px;left:20px;z-index:10}.poweredBy__3wG{position:absolute;right:20px;bottom:20px;z-index:30}.poweredBy__3wG a{color:#333;font-weight:500}\n", "", {"version":3,"sources":["/home/myovchev/development/nodejs/parse-graphql/src/components/Layout/src/components/Layout/index.scss"],"names":[],"mappings":"AAEA,UACE,kBACA,YACA,aACA,gBAAiB,CAClB,cAGC,kBACA,YACA,YACA,8BAA+B,CAJjC,wBAOI,WACA,gBAAiB,CAClB,iBAID,kBACA,UACA,UACA,UAAW,CACZ,gBAGC,kBACA,WACA,YACA,UAAW,CAJb,kBAOI,WACA,eAAgB,CACjB","file":"index.scss","sourcesContent":["@import '~assets/css/_globals.scss';\n\n.app {\n  position: relative;\n  width: 100vw;\n  height: 100vh;\n  padding-top: 70px;\n}\n\n.content {\n  position: relative;\n  width: 100vw;\n  height: 100%;\n  transition: opacity, filter .4s;\n\n  &.blur {\n    opacity: .6;\n    filter: blur(4px);\n  }\n}\n\n.configLink {\n  position: absolute;\n  top: 100px;\n  left: 20px;\n  z-index: 10;\n}\n\n.poweredBy {\n  position: absolute;\n  right: 20px;\n  bottom: 20px;\n  z-index: 30;\n\n  a {\n    color: #333;\n    font-weight: 500;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"app": "app__19O",
	"content": "content__3Hk",
	"blur": "blur__XM-",
	"configLink": "configLink__1br",
	"poweredBy": "poweredBy__3wG"
};