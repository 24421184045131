// import playground from './playground';
import auth from './auth';
import config from './config';
import todo from './todo';
import def from './default';

// Gather Apollo client defaults from modules
export const defaults = {
  ...auth.defaults,
  ...def.defaults,
};

// Gather Apollo client mutation resolvers from modules
export const Mutation = {
  ...auth.Mutation,
  ...def.Mutation,
};

export default {
  routes: [
    // ...playground.routes,
    ...auth.routes,
    ...config.routes,
    ...todo.routes,
    // WARNING - keep always last!!!
    ...def.routes,
  ],
  topRightBar: [
    ...auth.topRightBar,
  ],
};
