exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".loaderDots__25t,.loaderDots__25t:before,.loaderDots__25t:after{width:10px;height:10px;display:inline-block;font-size:0;animation:loadingDots__1WY .8s linear infinite;border-radius:50%;background-color:transparent}.loaderDots__25t{position:relative;margin:auto 15px;animation-delay:.2s}.loaderDots__25t:before{position:absolute;left:-15px;content:'';animation-delay:0s}.loaderDots__25t:after{position:absolute;right:-15px;content:'';animation-delay:.4s}@keyframes loadingDots__1WY{0%{background-color:transparent}50%{background-color:#159cee}100%{background-color:transparent}}\n", "", {"version":3,"sources":["/home/myovchev/development/nodejs/parse-graphql/src/components/LoaderDots/src/components/LoaderDots/index.scss"],"names":[],"mappings":"AAKA,gEAGE,WACA,YACA,qBACA,YACA,+CAEA,kBACA,4BAA6B,CAC9B,iBAGC,kBACA,iBACA,mBAAoB,CAHtB,wBAMI,kBACA,WAEA,WACA,kBAAmB,CAVvB,uBAcI,kBACA,YAEA,WACA,mBAAoB,CACrB,4BAID,GACE,4BAA6B,CAE/B,IACE,wBAAyB,CAE3B,KACE,4BAA6B,CAAA,CAAA","file":"index.scss","sourcesContent":["@import '~assets/css/_globals.scss';\n\n$size: 10px;\n$spacing: $size / 2 * 3;\n\n.loaderDots,\n.loaderDots:before,\n.loaderDots:after {\n  width: $size;\n  height: $size;\n  display: inline-block;\n  font-size: 0;\n  animation: loadingDots .8s linear infinite;\n\n  border-radius: 50%;\n  background-color: transparent;\n}\n\n.loaderDots {\n  position: relative;\n  margin: auto $spacing;\n  animation-delay: .2s;\n\n  &:before {\n    position: absolute;\n    left: $spacing * -1;\n\n    content: '';\n    animation-delay: 0s;\n  }\n\n  &:after {\n    position: absolute;\n    right: $spacing * -1;\n\n    content: '';\n    animation-delay: .4s;\n  }\n}\n\n@keyframes loadingDots {\n  0% {\n    background-color: transparent;\n  }\n  50% {\n    background-color: #159cee;\n  }\n  100% {\n    background-color: transparent;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"loaderDots": "loaderDots__25t",
	"loadingDots": "loadingDots__1WY"
};