import React from 'react';
import { Route } from 'react-router-dom';

import { ActiveTodos, AllTodos, CompletedTodos } from './Todo';

export default {
  routes: [
    <Route key="todo-all" exact path="/" component={AllTodos} />,
    <Route key="todo-active" exact path="/active" component={ActiveTodos} />,
    <Route key="todo-completed" exact path="/completed" component={CompletedTodos} />,
  ],
};
