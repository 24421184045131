import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose, mapProps, withProps } from 'recompose';

export {
  compose,
  mapProps,
  withProps,
};

/**
 * Low level enhancer - render if condition is falsy
 */
export const withMaybe = conditionFn => Component => props => (
  conditionFn(props)
    ? null
    : <Component {...props} />
);

/**
 * Low level enhancer - render OTHER component if condition is truthy
 */
export const withEither = (conditionFn, OtherComponent, eitherProps = {}) => (
  Component => (props) => {
    const ep = typeof eitherProps === 'function'
      ? eitherProps(props)
      : eitherProps;

    return conditionFn(props)
      ? <OtherComponent {...ep} />
      : <Component {...props} />;
  }
);

// Provide react router history object as prop
export const withRouterHistory = compose(
  withRouter,
  mapProps(({ staticContext, history, match, location, ...props }) => ({
    ...props,
    history,
  }))
);

// Provide react router location object as prop
export const withRouterLocationAndHistory = compose(
  withRouter,
  mapProps(({ staticContext, history, match, location, ...props }) => ({
    ...props,
    location,
    history,
  }))
);
