import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Form, { FormHandler, getFieldErrors } from 'components/Form';
import FormHolder from 'components/FormHolder';
import Input from 'components/Input';
import Button from 'components/Button';
import Notify from 'components/Notify';
import validations from './validations';
import styles from '../Auth.scss';


class LoginForm extends Component {
  state = {
    fields: {
      email: '',
      password: '',
    },
    loading: false,
    errors: {},
  }

  static propTypes = {
    formData: PropTypes.shape({
      email: PropTypes.string,
      message: PropTypes.string,
      error: PropTypes.string,
    }).isRequired,
    resetLogin: PropTypes.func.isRequired,
    setSession: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
  }

  handler = new FormHandler(this, {
    submit: () => {
      const { login } = this.props;
      const { fields } = this.state;
      const { email, password } = fields;

      return login(email, password)
        .then(({ data }) => data.login);
    },
    validations,
    onSuccess: (userData) => {
      const { setSession } = this.props;
      const { sessionToken, __typename, ...user } = userData;
      localStorage.setItem('sessionToken', sessionToken);
      setSession(true, user);
    },
  });

  componentDidMount() {
    const { formData } = this.props;
    const { fields } = this.state;
    // save default email as it will be reset on first submit
    this.setState({ fields: {
      ...fields,
      email: formData.email,
    } });
  }

  handleSubmit = async (ev) => {
    ev.persist();
    const { resetLogin } = this.props;
    // clear router error/message
    await resetLogin();
    const { fields } = this.state;
    this.handler.handleSubmit({
      values: fields,
    }, ev);
  }

  onBlur = e => (
    this.handler.onValidate(e, { values: this.state.fields }) // eslint-disable-line
  )

  onChange = (e) => {
    const { fields } = this.state;
    this.handler.onValidate(e, { values: fields });
    this.handler.onChange(e);
  }

  render() {
    const { formData } = this.props;
    const { loading, errors: errObject, fields } = this.state;
    const { errors, hasError } = getFieldErrors(fields, errObject);

    const btnDisabled = loading || hasError;

    return (
      <div className={styles.authWrapper}>
        <FormHolder>
          <Form onSubmit={this.handleSubmit}>
            <h2>Log In</h2>

            <Notify error message={errors.__default__} />
            <Notify error message={formData.error} />
            <Notify success message={formData.message} />

            <Input
              name="email"
              placeholder="email address"
              type="text"
              autoComplete="email"
              value={fields.email}
              disabled={loading}
              error={errors.email}
              onBlur={this.onBlur}
              onChange={this.onChange}
            />

            <Input
              name="password"
              placeholder="password"
              type="password"
              autoComplete="current-password"
              disabled={loading}
              error={errors.password}
              onBlur={this.onBlur}
              onChange={this.onChange}
            />

            <Button
              type="submit"
              disabled={btnDisabled}
              onClick={this.handleSubmit}
              success
            >
              {'Log In'}
            </Button>
          </Form>
        </FormHolder>
        <Link to="/register">Create an Account!</Link>
      </div>
    );
  }
}


export default LoginForm;
