import React, { PureComponent, Fragment } from 'react';

import { appId, appKey, graphqlUri } from 'utils/apollo';
import { withRouterLocationAndHistory } from 'utils/compose';
import { getCustomConfig, useConfig } from 'utils/misc';

class ConfigChecker extends PureComponent {
  componentDidMount() {
    const config = useConfig({ appId, appKey, graphqlUri }, getCustomConfig());
    if (!config.appId || !config.appKey || !config.graphqlUri) {
      const { history, location } = this.props;
      if (!location.pathname !== '/config') {
        history.push('/config');
      }
    }
  }

  render() {
    const { children } = this.props;
    return (
      <Fragment>
        {children}
      </Fragment>
    );
  }
}

export default withRouterLocationAndHistory(ConfigChecker);
