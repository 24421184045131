import React, { Fragment } from 'react';

import Header from './Header';
import styles from './index.scss';

const Layout = ({ children, topRightBar = [], screenLoading }) => (
  <Fragment>
    <div className={styles.app}>
      <Header>
        {topRightBar.length > 0 && topRightBar.map(item => (
          <Fragment key={`item-${item.key}`}>{item}</Fragment>
        ))}
      </Header>
      <div className={[styles.content, screenLoading ? styles.blur : ''].join(' ')}>
        {children}
      </div>
    </div>
    <p className={styles.poweredBy}>
      {'Powered by '}
      <a href="https://www.sashido.io">SashiDo.io</a>
    </p>
  </Fragment>
);

export default Layout;
