import React from 'react';

import styles from './Todo.scss';

export default ({ todo, toggleTodo }) => {
  const { id, completed, label } = todo;
  return (
  <div className={[styles.todoItem, completed ? styles.completed : ''].join(' ')}>
    <label htmlFor={id}>
        <input type="checkbox" id={id} name={id} checked={completed} onChange={toggleTodo} readOnly />
      <span />
      {label}
    </label>
  </div>
  );
};
