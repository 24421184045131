import React from 'react';
import { Route } from 'react-router-dom';

import Login from './Login';
import Register from './Register';
import Logout from './Logout';

import loginLocal from './Login/local';
import local from './local';

export default {
  routes: [
    <Route key="login" exact path="/login" component={Login} />,
    <Route key="register" exact path="/register" component={Register} />,
  ],
  topRightBar: [
    <Logout key="logout" />,
  ],
  Mutation: {
    ...local.Mutation,
    ...loginLocal.Mutation,
  },
  defaults: {
    ...local.defaults,
    ...loginLocal.defaults,
  },
};
