export function handleGraphQLErrors(error, state) {
  if (!error) return state;

  // e.graphQLErrors, Object.keys(e.networkError);
  const errs = {};
  const { graphQLErrors, networkError } = error;
  if (graphQLErrors && graphQLErrors.length > 0) {
    graphQLErrors.reduce((acc, err) => Object.assign(
      acc,
      {
        __default__: `${acc.__default__ ? `${acc.__default__}\n` : ''}${err.message}`,
      }
    ), errs);
  } else if (networkError) {
    Object.assign(errs, {
      __default__: 'Network error',
    });
  }
  return {
    ...state,
    errors: errs,
  };
}

export function queryErrorsToArray(error) {
  if (!error) return [];

  const errs = [];
  const { graphQLErrors, networkError } = error;
  if (graphQLErrors && graphQLErrors.length > 0) {
    graphQLErrors.forEach(err => errs.push(err.message));
  } else if (networkError) {
    errs.push('Network error');
  }

  return errs;
}

export const connectionToData = (connection) => {
  if (!connection) {
    return {
      entities: [],
      pageInfo: {},
    };
  }
  const entities = connection.edges.map((edge) => {
    const item = {
      ...edge.node,
    };
    if (edge.cursor) {
      item.cursor = edge.cursor;
    }
    return item;
  });
  return { entities, pageInfo: connection.pageInfo };
};

export const getCustomConfig = () => {
  const appId = localStorage.getItem('appId') || '';
  const appKey = localStorage.getItem('appKey') || '';
  const graphqlUri = localStorage.getItem('graphqlUri') || '';
  return { appId, appKey, graphqlUri };
};

export const setCustomConfig = (config) => {
  const { appId, appKey, graphqlUri } = config;
  localStorage.setItem('appId', appId);
  localStorage.setItem('appKey', appKey);
  localStorage.setItem('graphqlUri', graphqlUri);
};

export const resetCustomConfig = () => {
  localStorage.removeItem('appId');
  localStorage.removeItem('appKey');
  localStorage.removeItem('graphqlUri');
};

export const useConfig = (defaultConfig, customConfig) => {
  if (customConfig.appId && customConfig.appKey && customConfig.graphqlUri) {
    return customConfig;
  }
  return defaultConfig;
};
