import React from 'react';

import { compose, withRouterLocationAndHistory } from 'utils/compose';
import { redirectWhenNotAuthorized } from 'app/Auth';

import TodoView from 'modules/todo/View';

import {
  withAllTodos,
  withToggleTodo,
  withAddTodo,
  withActiveTodos,
  withCompletedTodos,
} from './enhancers';

import TodoForm from './Form';


const EnhancedForm = compose(
  withAddTodo,
  withRouterLocationAndHistory
)(TodoForm);

const TodoAll = compose(
  redirectWhenNotAuthorized(),
  withAllTodos,
  withToggleTodo
)(TodoView);

const TodoActive = compose(
  redirectWhenNotAuthorized(),
  withActiveTodos,
  withToggleTodo
)(TodoView);

const TodoCompleted = compose(
  redirectWhenNotAuthorized(),
  withCompletedTodos,
  withToggleTodo
)(TodoView);

export const AllTodos = () => (
  <TodoAll>
    <EnhancedForm />
  </TodoAll>
);

export const ActiveTodos = () => (
  <TodoActive>
    <EnhancedForm />
  </TodoActive>
);

export const CompletedTodos = () => (
  <TodoCompleted>
    <EnhancedForm />
  </TodoCompleted>
);
