import React, { Component } from 'react';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Isvg from 'react-inlinesvg';

import AuthChecker from 'app/Auth/Checker';
import ConfigChecker from 'app/ConfigChecker';
import ScreenLoading, { withScreenLoading } from 'app/ScreenLoading';
import { withRouterHistory, compose } from 'utils/compose';
import client from 'utils/apollo';
import modules from 'modules';

import LayoutView from 'components/Layout';

const Layout = compose(
  withScreenLoading,
  withRouterHistory
)(LayoutView);

const { routes, topRightBar } = modules;

class App extends Component {
  state = {}

  render() {
    return (
      <ApolloProvider client={client}>
        <AuthChecker>
          <Router>
            <ConfigChecker>
              <Layout topRightBar={topRightBar}>
                <Switch>{routes}</Switch>
              </Layout>
              <ScreenLoading />
            </ConfigChecker>
          </Router>
        </AuthChecker>
        <Isvg src="/assets/images/sprite.svg" uniquifyIDs={false} />
      </ApolloProvider>
    );
  }
}

export default App;
