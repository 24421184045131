import React from 'react';

import styles from './View.scss';

const ConfigForm = ({ children }) => (
  <div className={styles.configView}>
    {children}
  </div>
);


export default ConfigForm;
