import React from 'react';

import styles from './index.scss';

const SegmentSelect = ({ labels, values, active, onChange }) => {
  const handleClick = (v) => {
    if (values[active] !== v) {
      onChange(v);
    }
  };

  const buttons = values.map((item, i) => (
    <button
      type="button"
      key={item}
      className={item.toLowerCase() === values[active].toLowerCase() ? styles.active : null}
      onClick={() => handleClick(i)}
    >
      {labels ? labels[i] : item}
    </button>
  ));

  return (
    <div className={styles.segmentSelect}>
      {buttons}
    </div>
  );
};

export default SegmentSelect;
