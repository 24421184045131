import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Form, { FormHandler, getFieldErrors } from 'components/Form';
import FormHolder from 'components/FormHolder';
import Input from 'components/Input';
import Button from 'components/Button';
import Notify from 'components/Notify';
import validations from './validations';
import styles from '../Auth.scss';


class RegisterForm extends Component {
  state = {
    fields: {
      email: '',
      password: '',
      passwordConfirm: '',
    },
    loading: false,
    errors: {},
  }

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    setLoginEmail: PropTypes.func.isRequired,
    setLoginMessage: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
  }

  handler = new FormHandler(this, {
    submit: () => {
      const { register } = this.props;
      const { fields } = this.state;
      const { email, password } = fields;

      return register(email, password);
    },
    validations,
    onSuccess: async () => {
      const { fields } = this.state;
      const { email } = fields;
      const { setLoginMessage, setLoginEmail, history } = this.props;
      await setLoginMessage('Registration success.');
      await setLoginEmail(email);
      history.push('/login');
    },
  });

  handleSubmit = (ev) => {
    ev.preventDefault();
    const { fields } = this.state;
    this.handler.handleSubmit({
      values: fields,
    }, ev);
  }

  onBlur = e => (
    this.handler.onValidate(e, { values: this.state.fields }) // eslint-disable-line
  )

  onChange = (e) => {
    const { fields } = this.state;
    this.handler.onValidate(e, { values: fields });
    this.handler.onChange(e);
  }

  render() {
    const { loading, errors: errObject, fields } = this.state;

    const { errors, hasError } = getFieldErrors(fields, errObject);

    const btnDisabled = loading || hasError;

    return (
      <div className={styles.authWrapper}>
        <FormHolder>
          <Form onSubmit={this.handleSubmit}>
            <h2>Register</h2>

            <Notify error message={errors.__default__} />

            <Input
              name="email"
              placeholder="email address"
              type="text"
              autoComplete="email"
              disabled={loading}
              error={errors.email}
              onBlur={this.onBlur}
              onChange={this.onChange}
            />

            <Input
              name="password"
              placeholder="password"
              type="password"
              autoComplete="password"
              disabled={loading}
              error={errors.password}
              onBlur={this.onBlur}
              onChange={this.onChange}
            />

            <Input
              name="passwordConfirm"
              placeholder="password"
              type="password"
              autoComplete="off"
              disabled={loading}
              error={errors.passwordConfirm}
              onBlur={this.onBlur}
              onChange={this.onChange}
            />

            <Button
              type="submit"
              disabled={btnDisabled}
              onClick={this.handleSubmit}
              success
            >
              {'Register'}
            </Button>
          </Form>
        </FormHolder>
        <Link to="/login" className={styles.signUp}>Have an account? Login</Link>
      </div>
    );
  }
}


export default RegisterForm;
