import { graphql } from 'react-apollo';

import {
  redirectWhenAuthorized,
} from 'app/Auth';

import { withSetLoginMessage, withSetLoginEmail } from 'app/local/login';
import { withRouterHistory, compose } from 'utils/compose';

import Register from './register.graphql';
import Form from './Form';

const register = mutate => (email, password) => mutate({
  variables: { input: { email, password, username: `username.${email}` } },
});

export default compose(
  // Disallow for already authorized sessions
  redirectWhenAuthorized(),
  // Provide router history prop
  withRouterHistory,
  // Provide set login message prop
  withSetLoginMessage,
  // Provide set login email prop
  withSetLoginEmail,
  // Provide reguster request prop
  graphql(Register, {
    props: ({ mutate }) => ({ register: register(mutate) }),
    options: { fetchPolicy: 'no-cache' },
  })
)(Form);
