import React from 'react';

import Notify from 'components/Notify';

import TodoItem from './Item';
import styles from './Todo.scss';

export default ({ todos, errors, children, createToggleTodo }) => (
  <div className={styles.todo}>
    <div className={styles.todoForm}>
      {children}
    </div>
    {errors.length > 0 && <Notify error message={errors.join('/n')} />}
    {(todos && todos.length === 0) && <p className={styles.noResults}>No ToDo&apos;s yet</p>}

    {
      todos && (
        <div className={styles.todoItems}>
          <div className={styles.todoItemsHolder}>
            {
              todos.map(todo => (
                <TodoItem key={todo.id} todo={todo} toggleTodo={createToggleTodo(todo)} />
              ))
            }
          </div>
        </div>
      )
    }
  </div>
);
