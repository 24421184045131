export default (
  schemaName,
  serverURL = 'http://YOUR_PARSE_INSTALLATION.com/parse/graphql',
  appID = 'YOUR_APP_ID',
  masterKey = 'YOUR_MASTER_KEY',
  body
) => {
  const headers = [[`-H "X-Parse-Application-Id: ${appID}" \\`]];
  headers.push([`-H "X-Parse-Master-Key: ${masterKey}" \\`]);
  headers.push(['-H "Content-type: application/json" \\']);

  let request = `curl -X POST \\\n${headers.join('\n')}\n`;

  request += `-d '${body}' \\\n`;

  request += `${serverURL.replace('/graphql', `/schemas/${schemaName}`)}`;
  return request;
};
